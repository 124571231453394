import axios from "axios";
import {DEFAULT_ERROR_TITLE, ERROR_SESSION_EXPIRED, ERROR_BAD_SESSION, API_OK, API_URL } from './Constants';
import { setLoading } from "./features/common";
import cookie from "js-cookie";

export function formatErrorMessage(errorMessage, t){
    const translatedErrorMessage = t(errorMessage);
    if (translatedErrorMessage !== errorMessage){
        errorMessage = errorMessage + " - " + translatedErrorMessage;
    }
    return errorMessage;
}

export function handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate) {
    const sessionErrors = [
        ERROR_SESSION_EXPIRED,
        ERROR_BAD_SESSION
    ];

    if(sessionErrors.includes(errorMessage)
        && (logout !== null)){
        if((errorMessage !== ERROR_SESSION_EXPIRED) && (typeof errorMessage !== 'undefined')){
            cookie.set('logout_error', errorMessage, { sameSite: 'strict' });
        }
        
        dispatch(logout());
        navigate("/", { replace: true });
    }else{
        if(typeof errorMessage !== 'undefined'){
            errorMessage = formatErrorMessage(errorMessage, t);
            dispatch(showErrorDialog({title : t(DEFAULT_ERROR_TITLE), text: errorMessage}));
        }
    }
}

export const checkApiSessionErrorMessage = (errorMessage, dispatch, logout, navigate) => {
    const sessionErrors = [
        ERROR_SESSION_EXPIRED,
        ERROR_BAD_SESSION,
    ];

    if(sessionErrors.includes(errorMessage)
        && (logout !== null)){
        if((errorMessage !== ERROR_SESSION_EXPIRED) && (typeof errorMessage !== 'undefined')){
            cookie.set('logout_error', errorMessage, { sameSite: 'strict' });
        }
        
        dispatch(logout());
        navigate("/", { replace: true });
    }else{
        return false;
    }
    return true;
}

export const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
);

export const displayMinutes = (minutes) => {
    const minutesPart = Math.floor(minutes);
    let secondsPart = Math.round(minutes * 60 % 60);
    if(minutesPart ===0 && secondsPart === 0) {
        secondsPart = 1;
    }
    secondsPart = secondsPart < 10 ? `0${secondsPart}` : secondsPart;
    return `${minutesPart}:${secondsPart}`;
}

export async function checkValidToken(navigate, dispatch, logout, forward = true, invalidLogout = true, invalidForward = null, altForward = '/successfully-logged-in') {
    dispatch(setLoading({loading : true}));

    if(await checkIsTokenValid()) {
        if(forward) {
            let redirectFrom = cookie.get('redirect_from');
            if(redirectFrom){
                window.setTimeout(function(){
                    window.location.href = redirectFrom+"?token="+cookie.get('token');
                }, 3000);
            } else {
                navigate(altForward);
                //return;
            }
        }
    } else if(cookie.get('token')) {
        if(invalidLogout) {
            dispatch(logout());
        }
        if(invalidForward) {
            navigate(invalidForward);
            //return;
        }
    };

    dispatch(setLoading({loading : false}));
}

export async function setLoadingShow(dispatch) {
    dispatch(setLoading({loading : true}));
}

export async function setLoadingHide(dispatch) {
    dispatch(setLoading({loading : false}));
}



export async function checkIsTokenValid() {
    const token = cookie.get('token');
    if(token){
        const redirectBackUrl = cookie.get('redirect_from');
        let config = {'headers': {"token": token, "lang": getLang()}};
        try {
            const reply = await axios.post(API_URL + '/isTokenValid', {url: (redirectBackUrl ?? window.location.href)}, config);
            if(reply.hasOwnProperty('data') && reply.data.hasOwnProperty('responseStatus') && reply.data.responseStatus === API_OK){
                if(reply.data.isTokenValid){
                    return true;
                }                     
            }
        } catch (e) {
            return false;
        }
    }
    return false;
}


export function getLang() {
    return cookie.get('lang');
}

import React, {useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isLoggedIn, logout} from "../../features/user";
//import { setLoading} from "../../features/common";
import { useTranslation } from 'react-i18next';
import {checkValidToken} from "../../Helper"

import Frame from '../widgets/Frame';

export function SuccessfullyLoggedIn() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const isUserLoggedIn = useSelector(isLoggedIn);

    useEffect(()=>{
        if(isUserLoggedIn) {
            checkValidToken(navigate, dispatch, logout, true, true, "/login");
        } else {
            navigate("/login");
        }
        /*if(isUserLoggedIn){
            if(searchParams.get("redirect")) {
                window.location.replace(searchParams.get("redirect"));
            }
        } else {
            navigate('/login');
        }*/
       // eslint-disable-next-line
    },[]);

    return (
            <Frame>
                <div className='main main-successfully-logged-in'>
                    <div className='main-top'>
                        <h1>{t("You've successfully logged in")}</h1>
                    </div>
                    <div className='main-body'>
                        <div className='right-main'>
                            <div className='right-main-inner'>
                                <h2>{t("You will be redirected to your account in a few seconds")}</h2>
                            </div>
                        </div>
                        <div className='left-main'>
                            <img src='/images/person-paper-plane.png' className='content-logo' alt=''/>
                        </div>
                    </div>
                </div>
            </Frame>
    );
}
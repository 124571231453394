import React, {useState} from 'react';
//import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_OK, API_URL, DEFAULT_ERROR_TEXT, API_EXCEPTION } from '../../Constants';
import {Trans,  useTranslation } from 'react-i18next';
import {getLang} from "../../Helper";

import Frame from '../widgets/Frame';

export function ResetPassword() {
    const [state, setState] = useState({ email: "", errors: {}, message: null });

    //const navigate = useNavigate();
    const { t } = useTranslation();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(!validateForm()) {
            let errorMessage = DEFAULT_ERROR_TEXT;

            let config = {'headers': {"lang": getLang()}};
            let resellerNick = process.env.REACT_APP_RESELLER_NICK;
            if(resellerNick && (typeof resellerNick !== "undefined")){
                config['headers']['reseller-nick'] = resellerNick;
            }
          
            const data = {email: state.email.trim()};
        
            try {
                const reply = await axios.post(API_URL + '/sendResetPasswordEmail', data, config);
                //const reply = {data: {responseStatus : API_EXCEPTION, message: 'HEEEEEEELLLLP'}};
                console.log(reply);
                if(reply.hasOwnProperty('data') && reply.data.hasOwnProperty('responseStatus') && reply.data.responseStatus === API_OK){
                    setState({
                        ...state,
                        message: "Reset passord sent"
                    });
                }else{
                    if((reply.data.responseStatus === API_EXCEPTION) && (reply.data.hasOwnProperty("message"))){
                        errorMessage = reply.data["message"];
                    }
                    setState({
                        ...state,
                        errors: {server_error: errorMessage}
                    });
                };
            } catch (e) {
                setState({
                    ...state,
                    errors: {server_error: errorMessage}
                });
            }
        }

    };

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.id]: e.target.value,
            errors: {},
            message: null
        });
    };

    const validateForm = () => {
        const errors = {};
        if (!state.email.trim()) {
            errors['email'] = 'Email is required';
        }

        setState({
            ...state,
            errors: errors,
            message: null
        });

        return Object.keys(errors).length > 0;
    };


    return (
            <Frame>
                <div className='main main-reset-password'>
                    <div className='main-top'>
                        <h1>{t("Reset password")}</h1>
                    </div>
                    <div className='main-body'>
                        <div className='right-main'>
                            <div className='right-main-inner'>
                                <h2>{t("No problem at all. We will help you to reset the password in a few easy steps.")}</h2>
                                <div className='mt30 input blue-border'><input className={state.errors.email?"error":""} type="text" id="email" placeholder={t("Email address")} onChange={(e) => handleChange(e)} /></div>
                                <div className='button button-space-between button-teal' onClick={(e) => handleSubmit(e)}>  <div className='mr20'>{t("Send me the password reset link")}</div> <img src='/images/less-than-white.png' className='ml20 button-icon less-than-icon' alt=''/> </div>
                                
                                {state.message?<h2 className='mt10 teal'><Trans i18nKey={state.message} /></h2>:""}

                                <h2 className='mt40 teal'><Trans i18nKey="After pressing the button, we will send you an email with the instruction for resetting your passowrd. Please make sure to also check your spam folder in case that you didn't receive the email within a few minutes." /></h2>

                                {Object.entries(state.errors).map(([key, msg]) => (
                                    <h2 key={key} className='mt20 error'><Trans i18nKey={msg} /></h2>
                                ))}
                            </div>
                        </div>    
                        <div className='left-main'>
                            <img src='/images/person-phone.png' className='content-logo' alt=''/>
                        </div>
                    </div>
                </div>
            </Frame>
    );
}
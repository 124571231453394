import cookie from "js-cookie";
import queryString from 'query-string';
import { useLocation, useNavigate, Outlet, Navigate } from 'react-router-dom';
import { THEME_SIM_CUSTOMERS } from '../../Constants';

const HanldeURLParameters = ({ children}) => {
    const location = useLocation();
    const { hash, pathname, search } = location;
    const params = queryString.parse(search);
    const redirectFrom = params?.redirect_from;
    const navigate = useNavigate();

    if(redirectFrom){
        const hostRegex = /^(?:https?:\/\/)?(?:www\.)?([^\/:]+)/;
        const match = redirectFrom.match(hostRegex);
        const hostname = match ? match[1] : null;

        if(hostname !== null){
            cookie.set("redirect_from",redirectFrom);
        }
    }

    const invalidToken = params?.invalid_token;
    const currentToken = cookie.get("token");
    if(invalidToken && currentToken && invalidToken === currentToken){
        cookie.remove("token");
    }

    if(process.env.REACT_APP_RESELLER_NICK === "SIM_K"
        || process.env.REACT_APP_RESELLER_NICK === "SIM_M"
        || process.env.REACT_APP_RESELLER_NICK === "MERKAZIA"
    ){
        return <Navigate to="/login-sim-customers" />;
    }else{
        return <Outlet />;
    }
};


export default HanldeURLParameters;
import React, {useEffect} from 'react';
import { useTranslation } from 'react-i18next'
import cookie from "js-cookie";
import { delay, getLang } from '../../Helper';


const Header = () => {

    const { i18n } = useTranslation()

    useEffect(()=>{
        let lang = getLang();
        if(!lang) {
            const userLang = navigator.language || navigator.userLanguage;
            lang = userLang==='en-US'?'en':'he';
        }
        requestChangeLanguage(lang);
        // eslint-disable-next-line
    },[]);

    const requestChangeLanguage = async (lang) => {
        await delay(1);
        changeLanguage(lang);
    }


    const changeLanguage = (lang) => {
        //console.log(lang);
        cookie.set('lang', lang, { sameSite: 'strict' });
        i18n.changeLanguage(lang);
        document.dir = lang==='en'?'ltr':'rtl';
    }

    return <div className="header">
        <img src='/images/header-logo.png' className='header-logo' alt=''/>
        <div className='header-icons'>
            <img src={'/images/flags/flag-usa.png'} onClick={() => changeLanguage('en')} className='ml5 mr5 flag-icon' alt=''/>
            <img src={'/images/flags/flag-israel.png'} onClick={() => changeLanguage('he')} className='ml5 mr5 flag-icon' alt=''/>
            <img src='/images/notification-icon.png' className='notification-icon mr15' alt=''/>
            <img src='/images/user-icon.png' className='user-icon' alt=''/>
        </div>
        
    </div>;
};


export default Header;
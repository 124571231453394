import { createSlice } from "@reduxjs/toolkit";

export const commonSlice = createSlice({
  name: "common",
  initialState: () => {
    return {loading: false};
  },
  reducers: {
    setLoading: (state, action) => {
      //console.log("reducers setLoading "+action.payload.loading);
      state.loading = action.payload.loading;
    }
  }
});

export const { setLoading } = commonSlice.actions;

export default commonSlice.reducer;

export const isLoading = (state) => state?.common?.loading;

import React, {useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import {useTranslation } from 'react-i18next';
import {delay} from "../../Helper"

import Frame from '../widgets/Frame';

export function PasswordReset() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(()=>{
        async function redirect() {
            await delay(2000);
            navigate('/login-verify');
        }
        redirect();
        // eslint-disable-next-line
    },[]);

    return (
            <Frame>
                <div className='main main-password-reset'>
                    <div className='main-top'>
                        <h1>{t("Password reset successfully")}</h1>
                    </div>
                    <div className='main-body'>
                        <div className='right-main'>
                            <div className='right-main-inner'>
                                <h2>{t("Your new password has been successfully saved. You will be redirected to the login screen within a few seconds.")}</h2>
                            </div>
                        </div>    
                        <div className='left-main'>
                            <img src='/images/person-pointing.png' className='content-logo' alt=''/>
                        </div>
                    </div>    
                </div>
            </Frame>
    );
}
import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login, isLoggedIn, pending2FA, logout} from "../../features/user";
//import { setLoading} from "../../features/common";
import axios from "axios";
import { API_OK, API_URL, DEFAULT_ERROR_TEXT, API_EXCEPTION } from '../../Constants';
import {Trans, useTranslation } from 'react-i18next';
import {checkValidToken, getLang} from "../../Helper"
import cookie from "js-cookie";
import Frame from '../widgets/Frame';

export function Login() {
    const [state, setState] = useState({ username: "", password: "", errors: {}, passwordForm: false, show: false });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const isUserLoggedIn = useSelector(isLoggedIn);
    
    useEffect(() => {
        let errorMessage = cookie.get('logout_error');
        if(errorMessage !== "null" && (typeof errorMessage !== 'undefined')){
            setState({
                ...state,
                errors: {server_error: errorMessage}
            });
            cookie.set('logout_error', "null", { sameSite: 'strict' });
        }
        // eslint-disable-next-line
      }, []);

    useEffect(()=>{
        if(isUserLoggedIn){
            checkValidToken(navigate, dispatch, logout);
            /*if(searchParams.get("redirect")) {
                window.location.replace(searchParams.get("redirect"));
            } else {
                navigate('/successfully-logged-in');
            }*/
        }
        // eslint-disable-next-line
    },[]);

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.id]: e.target.value,
            errors: {}
        });
    };

    const handleSubmitUsername = async (e) => {
        e.preventDefault();
        if(state.username !== "") {
            setState({
                ...state,
                errors: {}
            });
            let errorMessage = t(DEFAULT_ERROR_TEXT);
          
            let config = {'headers': {"lang": getLang()}};
            const data = {userName: state.username};
            try {
                const reply = await axios.post(API_URL + '/checkUsername', data, config);
                //const reply = {data: {responseStatus : API_OK}};
                if(reply.hasOwnProperty('data') && reply.data.hasOwnProperty('responseStatus') && reply.data.responseStatus === API_OK){
                    //cookie.set('logout_error', "logout_error", { sameSite: 'strict' });
                    setState({
                        ...state,
                        passwordForm: true,
                        errors: {}
                    });
                }else{
                    if((reply.data.responseStatus === API_EXCEPTION) && (reply.data.hasOwnProperty("message"))){
                        errorMessage = reply.data["message"];
                      }
                      setState({
                        ...state,
                        errors: {server_error: errorMessage}
                    });
                };
            } catch (e) {
                setState({
                    ...state,
                    errors: {server_error: errorMessage}
                });
            }
        } else {
            setState({
                ...state,
                errors: {username: 'Username is required'}
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(state.password !== "") {
            setState({
                ...state,
                errors: {}
            });
            let errorMessage = t(DEFAULT_ERROR_TEXT);
          
            let config = {'headers': {"lang": getLang()}};
            const data = {userName: state.username, password: state.password};
        
            try {
                const reply = await axios.post(API_URL + '/login', data, config);
                if(reply.hasOwnProperty('data') && reply.data.hasOwnProperty('responseStatus') && reply.data.responseStatus === API_OK
                        && reply.data.hasOwnProperty('token')){
                    if(reply.data.hasOwnProperty('requires2FA') && reply.data.requires2FA) {
                        dispatch(pending2FA({token : reply.data.token}));
                        navigate("/login-verify");
                    } else {
                        dispatch(login({token : reply.data.token}));
    
                        let redirectFrom = cookie.get('redirect_from');
                        if(redirectFrom){
                            window.location.href = redirectFrom+"?token="+reply.data.token;
                        } else {
                            navigate("/successfully-logged-in");
                        }
                    }
                }else{
                    if((reply.data.responseStatus === API_EXCEPTION) && (reply.data.hasOwnProperty("message"))){
                        errorMessage = reply.data["message"];
                      }
                      setState({
                        ...state,
                        errors: {server_error: errorMessage}
                    });
                };
            } catch (e) {
                setState({
                    ...state,
                    errors: {server_error: errorMessage}
                });
            }

        } else {
            setState({
                ...state,
                errors: {password: 'Password is required'}
            });
            return;
        } 
    };

    const hasErrors = () => {
        return Object.keys(state.errors).length > 0;
    }


    const handlePreviousClick = (e) => {
        e.preventDefault();
        setState({
            ...state,
            passwordForm: false,
            error: false
        });
    };


    const restoreUsernameClick = (e) => {
        e.preventDefault();
        navigate('/restore-username');
    };

    const registerClick = (e) => {
        e.preventDefault();
        navigate('/register');
    };

    const resetPasswordClick = (e) => {
        e.preventDefault();
        navigate('/reset-password');
    };


    const toggleShowPassword = (e) => {
        if(e.target.parentElement.querySelector('input')) {
            e.target.parentElement.querySelector('input').type = e.target.parentElement.querySelector('input').type === "password"?"text":"password";
        }
    };


    const getUsernameForm = () => {
        return (
            <div className='right-main'>
                <div className='right-main-inner'>
                    <h2>{t("Please insert the username for login")}</h2>
                    <div className='mt60 input blue-border'><input className={state.errors.username?"error":""} type="text" id="username" placeholder={t("Please insert the username")} value={state.username} onChange={(e) => handleChange(e)} /></div>
                    <div className='button button-space-between button-teal' onClick={(e) => handleSubmitUsername(e)}> <div className='mr20'>{t("next")}</div> <img src='/images/less-than-white.png' className='ml20 button-icon less-than-icon' alt=''/> </div>
                    {Object.entries(state.errors).map(([key, msg]) => (
                        <h2 key={key} className='mt20 error'><Trans i18nKey={msg} /></h2>
                    ))}
                    {!hasErrors()?<h2 className='mt20 teal pointer' onClick={(e) => restoreUsernameClick(e)}><Trans i18nKey="Did you forget your username? <strong>Press here to restore it</strong>" /></h2>:""}
                    {!hasErrors()?<h2 className='teal pointer' onClick={(e) => registerClick(e)}><Trans i18nKey="Don't have a username yet? <strong>Press here</strong>" /></h2>:""}
                    {/* {hasErrors()?<h2 className='mt20 error'><Trans i18nKey="c1" /></h2>:""} */}
                    {hasErrors()?<h2><Trans i18nKey="Please try again or contact our <strong>customer support</strong>" /></h2>:""}
                </div>
            </div>
        )
    }

    const getPasswordForm = () => {
        return (
            <div className='right-main'>
                <div className='right-main-inner'>
                    <h2>{t("Please insert your password")}</h2>
                    <div className='mt10 button button-space-between button-teal' onClick={(e) => handlePreviousClick(e)}><img src='/images/greater-than-white.png' className='mr20 greater-than-icon' alt=''/> {state.username} <div className='ml40'></div></div>
                    <div className='input blue-border'> <input className={state.errors.password?"error":""} type="password" id="password" placeholder={t("Password")}  onChange={(e) => handleChange(e)} /><img src='/images/show-password-icon.png' className='input-icon ml20' onClick={(e) => toggleShowPassword(e)} alt=''/></div>
                    <div className='button button-space-between button-teal' onClick={(e) => handleSubmit(e)}>  <div className='mr20'>{t("next")}</div> <img src='/images/less-than-white.png' className='ml20 button-icon less-than-icon' alt=''/> </div>
                    {Object.entries(state.errors).map(([key, msg]) => (
                        <h2 key={key} className='mt20 error'><Trans i18nKey={msg} /></h2>
                    ))}
                    <h2 className='mt40 teal pointer' onClick={(e) => resetPasswordClick(e)}><Trans i18nKey="Forgot your password? <strong>Press here to reset it</strong>" /></h2>
                    {/*{hasErrors()?<h2 className='mt40 error'><Trans i18nKey="f1" /></h2>:""} 
                    {hasErrors()?<h2><Trans i18nKey="f2" /></h2>:""}*/}
                </div>
            </div>
        )
    }

    return (
        <Frame show={state.show}>
            <div className='main main-login'>
                <div className='main-top'>
                    <h1>{t(state.passwordForm?"We're almost there...":"Welcome back to the Yemot Hamessiah")}</h1>
                </div>
                <div className='main-body'>
                    {state.passwordForm?getPasswordForm():getUsernameForm()}
                    <div className='left-main'>
                        <img src='/images/person-phone.png' className='content-logo' alt=''/>
                    </div>
                </div>
            </div>
        </Frame>
    );
}
import { createSlice } from "@reduxjs/toolkit";
import cookie from "js-cookie";

//const NOTLOGGEDIN = null;
const LOGGEDIN = 1;
const REGISTER = 2;
const PENDING2FA = 3;

const initialStateValue = { tokenType: null/*, isLoggedIn: false*/};

export const userSlice = createSlice({
  name: "user",
  initialState: () => {
    if(cookie.get('token')){
      //console.log(cookie.get('token'));
      if(cookie.get('token').startsWith("R--")) {
        return {value: {tokenType: REGISTER}};
      } else if(cookie.get('token').startsWith("P--")) {
        return {value: {tokenType: PENDING2FA}};
      } else {
        return {value: {tokenType: LOGGEDIN}};
      }
    }else{
      return {value: initialStateValue};
    }
  },
  reducers: {
    register: (state, action) => {
      //console.log("reducers register");
      state.value.tokenType = REGISTER;
      cookie.set('token', "R--"+action.payload.token, { sameSite: 'strict' });
    },

    login: (state, action) => {
      state.value.tokenType = LOGGEDIN;
      cookie.set('token', action.payload.token, { sameSite: 'strict' });
      //cookie.set('is_logged_in', '1');
    },

    pending2FA: (state, action) => {
      state.value.tokenType = PENDING2FA;
      cookie.set('token', "P--"+action.payload.token, { sameSite: 'strict' });
    },

    logout: (state) => {
      state.value = initialStateValue;
      cookie.remove('token'); //, { sameSite: 'strict' }
      //cookie.remove('is_logged_in');
    },
  },
});

export const { register, login, pending2FA, logout } = userSlice.actions;

export default userSlice.reducer;

export const isRegister = (state) => state?.user?.value?.tokenType === REGISTER;
export const isLoggedIn = (state) => state?.user?.value?.tokenType === LOGGEDIN;
export const isPending2FA = (state) => state?.user?.value?.tokenType === PENDING2FA;
  //state.posts.posts.find(post => post.id === postId);

export const userToken = () => cookie.get('token')?cookie.get('token').replace("R--" ,"").replace("P--" ,""):null;

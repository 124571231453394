import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isRegister, userToken, logout } from "../../features/user";
import axios from "axios";
import { API_OK, API_URL, DEFAULT_ERROR_TEXT, API_EXCEPTION } from '../../Constants';
import {Trans,  useTranslation } from 'react-i18next';
import {checkApiSessionErrorMessage, displayMinutes, getLang} from "../../Helper"

import Frame from '../widgets/Frame';

export function VerifyEmail() {
    const [state, setState] = useState({ code: "", errors: {}, message: null, email: "" });

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const isUserRegister = useSelector(isRegister)


    useEffect(()=>{
        if(!isUserRegister){
           navigate("/register");
        } else {
            getRegistrationData();
        }
        // eslint-disable-next-line
    },[]);


    const getRegistrationData = async (e) => {
        let errorMessage = DEFAULT_ERROR_TEXT;
        
        let config = isUserRegister?{'headers': {"token": userToken(), "lang": getLang()}}:{'headers': {"lang": getLang()}};
        let resellerNick = process.env.REACT_APP_RESELLER_NICK;
        if(resellerNick && (typeof resellerNick !== "undefined")){
            config['headers']['reseller-nick'] = resellerNick;
        }
        try {
            const reply = await axios.get(API_URL + '/getRegistrationData', config);
            if(reply.hasOwnProperty('data') && reply.data.hasOwnProperty('data') && reply.data.hasOwnProperty('responseStatus') && reply.data.responseStatus === API_OK) {
                setState({
                    ...state,
                    email: reply.data.data.email
                });
            } else {
                if((reply.data.responseStatus === API_EXCEPTION) && (reply.data.hasOwnProperty("message"))){
                    errorMessage = reply.data["message"];
                }
                if(!checkApiSessionErrorMessage(errorMessage, dispatch, logout, navigate)) {
                    setState({
                        ...state,
                        errors: {server_error: errorMessage}
                    });
                }
            }
        } catch (e) {
            setState({
                ...state,
                errors: {server_error: errorMessage}
            });
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(!validateForm()) {
            let errorMessage = DEFAULT_ERROR_TEXT;

            let config = {'headers': {"token": userToken(), "lang": getLang()}};
            let resellerNick = process.env.REACT_APP_RESELLER_NICK;
            if(resellerNick && (typeof resellerNick !== "undefined")){
                config['headers']['reseller-nick'] = resellerNick;
            }
          
            const data = {code: state.code.trim(), sendPhoneVerificationCode: true};
        
            try {
                const reply = await axios.post(API_URL + '/checkEmailVerificationCode', data, config);
                //const reply = {data: {responseStatus : API_EXCEPTION, message: 'HEEEEEEELLLLP'}};
                console.log(reply);
                if(reply.hasOwnProperty('data') && reply.data.hasOwnProperty('responseStatus') && reply.data.responseStatus === API_OK){
                    navigate("/verify-phone");
                }else{
                    if((reply.data.responseStatus === API_EXCEPTION) && (reply.data.hasOwnProperty("message"))){
                        errorMessage = reply.data["message"];
                    }
                    if(!checkApiSessionErrorMessage(errorMessage, dispatch, logout, navigate)) {
                        let errors = {server_error: errorMessage};

                        if((reply.data.responseStatus === API_EXCEPTION) && (reply.data.hasOwnProperty("tryAgainInMinutes"))){
                            errors = {...errors, tryAgainInMinutes: t('Please try again in {{minutes}} minutes', {minutes: displayMinutes(reply.data["tryAgainInMinutes"])})}
                        }
    
                        setState({
                            ...state,
                            errors: errors,
                            message: null
                        });
                    }
                };
            } catch (e) {
                setState({
                    ...state,
                    errors: {server_error: errorMessage},
                    message: null
                });
            }
        }
    };


    const handleResendCode = async (e) => {
        e.preventDefault();
        let errorMessage = DEFAULT_ERROR_TEXT;

        let config = {'headers': {"token": userToken(), "lang": getLang()}};
        let resellerNick = process.env.REACT_APP_RESELLER_NICK;
        if(resellerNick && (typeof resellerNick !== "undefined")){
            config['headers']['reseller-nick'] = resellerNick;
        }
    
        try {
            const reply = await axios.post(API_URL + '/sendEmailVerificationCode', {}, config);
            if(reply.hasOwnProperty('data') && reply.data.hasOwnProperty('responseStatus') && reply.data.responseStatus === API_OK){
                setState({
                    ...state,
                    errors: {},
                    message: "Phone verification code sent"
                });
            }else{
                if((reply.data.responseStatus === API_EXCEPTION) && (reply.data.hasOwnProperty("message"))){
                    errorMessage = reply.data["message"];
                }
                if(!checkApiSessionErrorMessage(errorMessage, dispatch, logout, navigate)) {
                    let errors = {server_error: errorMessage};

                    if((reply.data.responseStatus === API_EXCEPTION) && (reply.data.hasOwnProperty("tryAgainInMinutes"))){
                        errors = {...errors, tryAgainInMinutes: t('Please try again in {{minutes}} minutes', {minutes: displayMinutes(reply.data["tryAgainInMinutes"])})}
                    }

                    setState({
                        ...state,
                        errors: errors,
                        message: null
                    });
                }
            };
        } catch (e) {
            setState({
                ...state,
                errors: {server_error: errorMessage},
                message: null
            });
        }

    };

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.id]: e.target.value,
            errors: {},
            message: null
        });
    };

    const validateForm = () => {
        const errors = {};
        if (!state.code.trim() /*|| state.firstName.trim().length < 4*/) {
            errors['code'] = 'Verification Code is required';
        }

        setState({
            ...state,
            errors: errors,
            message: null
        });

        return Object.keys(errors).length > 0;
    };

    return (
        <Frame>
            <div className='main main-verify-phone'>
                <div className='main-top'>
                    <h1>{t("Verify your email")}</h1>
                </div>
                <div className='main-body'>
                    <div className='right-main'>
                        <div className='right-main-inner'>
                            <h2><Trans i18nKey="We just sent a verification code to {{email}}. Please insert it here" values={{email: state.email}} /></h2>
                            <div className='mt30 input blue-border'><input className={state.errors.code?"error":""}  type="text" id="code" placeholder={t("Verification Code")} onChange={(e) => handleChange(e)} /></div>
                            <div className='button button-space-between button-teal' onClick={(e) => handleSubmit(e)}>  <div className='mr20'>{t("next")}</div> <img src='/images/less-than-white.png' className='ml20 button-icon less-than-icon' alt=''/> </div>
                            <h2 className='mt40 teal pointer' onClick={(e) => handleResendCode(e)}><Trans i18nKey="I didn't receive the code, <strong>please resend it</strong>" /></h2>
                            {state.message?<h2 className='teal'><Trans i18nKey={state.message} /></h2>:""}
                            {Object.entries(state.errors).map(([key, msg]) => (
                                <h2 key={key} className='mt20 error'><Trans i18nKey={msg} /></h2>
                            ))}

                            {/* {state.error?<h2 className='mt10 error'><Trans i18nKey="kk4" /></h2>:""}
                            {state.error?<h2 className='error'><Trans i18nKey="kk5" /></h2>:""}
                            {state.error?<h2><Trans i18nKey="kk6" /></h2>:""} */}
                        </div>
                    </div>    
                    <div className='left-main'>
                        <img src='/images/person-computer.png' className='content-logo' alt=''/>
                    </div>
                </div>
            </div>
        </Frame>
    );
}
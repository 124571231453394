import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isLoggedIn, userToken, logout } from "../../features/user";
import axios from "axios";
import { API_OK, API_URL, DEFAULT_ERROR_TEXT, API_EXCEPTION } from '../../Constants';
import {Trans,  useTranslation } from 'react-i18next';
import {checkApiSessionErrorMessage, checkValidToken, getLang} from "../../Helper"

import Frame from '../widgets/Frame';

export function TwoStepVerification() {
    const [state, setState] = useState({ errors: false });

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const isUserLoggedIn = useSelector(isLoggedIn);

    useEffect(()=>{
        if(isUserLoggedIn){
            checkValidToken(navigate, dispatch, logout, false, true, "/login");
        } else {
            navigate("/login");
        }
        // eslint-disable-next-line
    },[]);


    const handleEnableClick = async (e) => {
        let errorMessage = DEFAULT_ERROR_TEXT;

        let config = {'headers': {"token": userToken(), "lang": getLang()}};
        let resellerNick = process.env.REACT_APP_RESELLER_NICK;
        if(resellerNick && (typeof resellerNick !== "undefined")){
            config['headers']['reseller-nick'] = resellerNick;
        }
        
        const data = {};
    
        try {
            const reply = await axios.post(API_URL + '/turnOn2fa', data, config);
            //const reply = {data: {responseStatus : API_EXCEPTION, message: 'HEEEEEEELLLLP'}};
            console.log(reply);
            if(reply.hasOwnProperty('data') && reply.data.hasOwnProperty('responseStatus') && reply.data.responseStatus === API_OK){
                navigate("/congratulations");
            }else{
                if((reply.data.responseStatus === API_EXCEPTION) && (reply.data.hasOwnProperty("message"))){
                    errorMessage = reply.data["message"];
                }
                if(!checkApiSessionErrorMessage(errorMessage, dispatch, logout, navigate)) {
                    setState({
                        ...state,
                        errors: {server_error: errorMessage}
                    });
                }
            };
        } catch (e) {
            setState({
                ...state,
                errors: {server_error: errorMessage}
            });
        }
    };

    const handleSkipClick = (e) => {
        navigate("/congratulations");
    };


    return (
            <Frame>
                <div className='main main-two-step-verification'>
                    <div className='main-top'>
                        <h1>{t("Two-step verification")}</h1>
                    </div>
                    <div className='main-body'>
                        <div className='right-main'>
                            <div className='right-main-inner'>
                                <h2>{t("Extra protection for your account")}</h2>
                                <h2>{t("Two-factor authentication adds an extra layer of protection for your account.")}</h2>
                                <h2>{t("On every login, please insert the additional code that will be sent to your phone")}</h2>
                                <h2>{t("Would you like to enable two-factor authentication?")}</h2>
                                <div className='mt40 button button-space-between button-teal' onClick={(e) => handleEnableClick(e)}>  <div className='mr20'>{t("Enable")}</div> <img src='/images/less-than-white.png' className='ml20 button-icon less-than-icon' alt=''/> </div>
                                <div className='mt20 button button-space-between button-darkteal' onClick={(e) => handleSkipClick(e)}>  <div className='mr20'>{t("Skip")}</div> <img src='/images/less-than-white.png' className='ml20 button-icon less-than-icon' alt=''/> </div>
                                {Object.entries(state.errors).map(([key, msg]) => (
                                    <h2 key={key} className='mt20 error'><Trans i18nKey={msg} /></h2>
                                ))}
                            </div>
                        </div>    
                        <div className='left-main'>
                            <img src='/images/person-pointing.png' className='content-logo' alt=''/>
                        </div>
                    </div>
                </div>
            </Frame>
    );
}
import React, {useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isLoggedIn, logout } from "../../features/user";
import { useTranslation } from 'react-i18next';
import {checkValidToken} from "../../Helper"

import Frame from '../widgets/Frame';

export function Congratulations() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const isUserLoggedIn = useSelector(isLoggedIn);

    useEffect(()=>{
        if(isUserLoggedIn) {
            checkValidToken(navigate, dispatch, logout, true, true, "/register", "/congratulations");
        } else {
            navigate("/register");
        }
        // eslint-disable-next-line
    },[]);


    return (
            <Frame>
                <div className='main main-congratulations'>
                    <div className='main-top'>
                        <h1>{t("Congratulations")} {/* firstname */} {t("Your account has been successfully created.")}</h1>
                    </div>
                    <div className='main-body'>
                        <div className='right-main'>
                            <div className='right-main-inner'>
                                <h2>{t("Now you can start using Yemot Hamashiach services")}</h2>
                            </div>
                        </div>    
                        <div className='left-main'>
                            <img src='/images/person-paper-plane.png' className='content-logo' alt=''/>
                        </div>
                    </div>
                </div>
            </Frame>
    );
}
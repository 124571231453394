import React from 'react';
import Header from "./Header";
import { useSelector } from "react-redux";
import {isLoading} from "../../features/common";




const Frame = ({ children }) => {
    const loading = useSelector(isLoading);

    const getChildren = (children) => {
        return children;
    }

    return <div className='frame'>
        <Header></Header>
        {!loading?<div className="content">
            {getChildren(children)}
        </div>:<></>}
     </div>;
};


export default Frame;
import React, {useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from '../../features/user';
//import cookie from "js-cookie";
import { useTranslation } from 'react-i18next';
//import { useSearchParams } from "react-router-dom";
import {checkValidToken} from "../../Helper"
import Frame from '../widgets/Frame';
// import axios from "axios";
// import { API_OK, API_URL} from '../../Constants';


export function Start() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    //const [searchParams] = useSearchParams();
    const dispatch = useDispatch();
    
    useEffect(() => {
        checkValidToken(navigate, dispatch, logout);
        // eslint-disable-next-line  
    }, []);



    const handleRegesterClick = (e) => {
        navigate("/register");
    };

    const handleLoginClick = (e) => {
        navigate("/login");
    }; 

    


    return (
            <Frame>
                <div className='main main-start'>
                    <div className='main-top'>
                        <h1>{t("Welcome to Yemot Hamashiach, the advanced communication solutions company")}</h1>
                    </div>
                    <div className='main-body'>
                        <div className='right-main'>
                            <div className='right-main-inner'>
                                <h2>{t("We are happy that you chose us")}</h2>
                                <h2>{t("To begin, please select the right option for you")}</h2> 
                                <div className='mt30'><div className='button blue-border' onClick={(e) => handleRegesterClick(e)}><img src='/images/register-icon.png' className='button-icon' alt=''/> {t("Registration")} </div></div>
                                <div className=''><div className='button blue-border' onClick={(e) => handleLoginClick(e)}><img src='/images/login-icon.png' className='button-icon' alt=''/> {t("Login")} </div></div>
                            </div>
                        </div>
                        <div className='left-main'>
                            <img src='/images/person-phone.png' className='content-logo' alt=''/>
                        </div>
                    </div>
                </div>
            </Frame>
    );
}